import Config from "@/utils/config";
import { mapGetters } from "vuex";

import { getIndexInfo } from "@/api/index/index";
export default {
  name: "index",
  components: {},
  data: () => {
    return {
      timer: null,
      loadingAd: true,
      loadingFloor: true,
      isMshow: false,
      isFshow: false,
      isPshow: false,
      isPshow01: false,
      iszqwdshow: false,
      isPshow11: false,
      isPshow21: false,
      isPshow31: false,
      isPshow41: false,
      isIndex: "",

      info: {},
      isComShow: false,
      authInfo: [],
      UserInfo: [],
    };
  },
  watch: {},
  created() {
    this.deviceType = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {},
  methods: {
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      // console.log(this.authInfo)
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];

        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "mfkfirstcontol";
            break;
          case 3:
            pathname = "mfRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 打开组件
    toCompon(key, name, nodename, Aname, titname) {
      this.isIndex = key;
      this.Aname = Aname;
      (this.historyname = name), (this.nodename = nodename);
      clearTimeout(this.grouptime);

      this.grouptime = setTimeout(() => {
        // this.maskbok = true
        switch (key) {
          case 0:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
             
              return (
                (this.mflmanyManual = true),
                (this.Manualtitname = name),
                (this.Manualnode = nodename),
                (this.Manualmark = Aname),
                (this.Manualvar = titname)
              );
            }
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = nodename),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = titname);
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = nodename)
            );
          case 5:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              
              return (
                (this.MfMAC = true),
                (this.MfMACCS1 = name),
                (this.MfMACCS2 = nodename),
                (this.MfMACCS3 = Aname),
                (this.MfMACCS4 = titname)
              );
            }
          case 6:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (
                (this.MfFMAZX = true),
                (this.MfFMAZXCS1 = name),
                (this.MfFMAZXCS2 = nodename),
                (this.MfFMAZXCS3 = Aname),
                (this.MfFMAZXCS4 = titname)
              );
            }
          case 7:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.isPshow01 = true);
            }
          case 8:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.isPshow02 = true);
            }
          case 9:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.isPshow03 = true);
            }
          case 10:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.isPshow04 = true);
            }
          case 11:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.isPshow05 = true);
            }
          case 12:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.isPshow06 = true);
            }
          case 13:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (
                (this.MfZQFOZX = true),
                (this.MfZQFOZXCS1 = name),
                (this.MfZQFOZXCS2 = nodename),
                (this.MfZQFOZXCS3 = Aname),
                (this.MfZQFOZXCS4 = titname)
              );
            }
          case 14:
            return (this.iszqwdshow = true);
        }
      }, 300);
    },
    isClose(val, val1) {
      // this.maskbok = false
      switch (val) {
        case "mflmanyManual":
          return (this.mflmanyManual = val1);
        case "MFL_MAC":
          return (this.MfMAC = val1);
        case "MFFMA_ZX":
          return (this.MfFMAZX = val1);
        case "MfZQFOZX_ZX":
          return (this.MfZQFOZX = val1);
        case "isPshow01":
          return (this.isPshow01 = val1);
        case "isPshow02":
          return (this.isPshow02 = val1);
        case "isPshow03":
          return (this.isPshow03 = val1);
        case "isPshow04":
          return (this.isPshow04 = val1);
        case "isPshow05":
          return (this.isPshow05 = val1);
        case "isPshow06":
          return (this.isPshow06 = val1);
        case "Historical":
          return (this.isHshow = val1);
        case "iszqwdshow":
          return (this.iszqwdshow = val1);
      }
      // if(val = 'Manual'){
      // 	this.isMshow = val1
      // }
    },
    // 监听滚动条
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollTop >= 680) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    destroyed() {
      // 离开该页面需要移除这个监听的事件，不然会报错
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
