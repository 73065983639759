var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"mflmac drag",attrs:{"id":"mflmac"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(" "+_vm._s(_vm.projectData.project)+"_M"+_vm._s(_vm.MfMACCS1)+"_C ")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v(_vm._s(_vm.MfMACCS1)+"磨优化控制模型")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"row1"},[_c('div',{staticClass:"fl row1-span1"},[_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("GML_SPH")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.GML_SP_HL,
                'GML_SPH',
                'GML_SP_HL',
                _vm.infoList.GML_SP_HL_node_string
              )}}},[_vm._v(" "+_vm._s("GML_SP_HL" in _vm.infoList ? _vm.infoList.GML_SP_HL : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("GML_SPL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.GML_SP_LL,
                ' GML_SPL',
                'GML_SP_LL',
                _vm.infoList.GML_SP_LL_node_string
              )}}},[_vm._v(" "+_vm._s("GML_SP_LL" in _vm.infoList ? _vm.infoList.GML_SP_LL : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1 row1-span2-jg1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("GML_"+_vm._s(_vm.MfMACCS1)+"_TC")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['GML_' + _vm.MfMACCS1 + '_TC'],
                'GML_' + _vm.MfMACCS1 + '_TC',
                'GML_' + _vm.MfMACCS1 + '_TC',
                _vm.infoList['GML_' + _vm.MfMACCS1 + '_TC_node_string']
              )}}},[_vm._v(" "+_vm._s(("GML_" + _vm.MfMACCS1 + "_TC") in _vm.infoList ? _vm.infoList["GML_" + _vm.MfMACCS1 + "_TC"] : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("GML_"+_vm._s(_vm.MfMACCS1)+"_PVL")]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'GML_' + _vm.MfMACCS1 + '_PVL',
                _vm.infoList['GML_' + _vm.MfMACCS1 + '_PVL_node_string'],
                'GML_' + _vm.MfMACCS1 + '_PVL_BM',
                'A磨煤机油压滤波值'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['GML_' + _vm.MfMACCS1 + '_PVL'],
                'GML_' + _vm.MfMACCS1 + '_PVL',
                _vm.infoList['GML_' + _vm.MfMACCS1 + '_PVL_node_string'],
                'GML_' + _vm.MfMACCS1 + '_PVL_BM'
              )}}},[_vm._v(" "+_vm._s(("GML_" + _vm.MfMACCS1 + "_PVL") in _vm.infoList ? _vm.infoList["GML_" + _vm.MfMACCS1 + "_PVL"] : 0)+" ")])])]),_c('div',{staticClass:"fl row1-span2 row1-span2-jg"},[_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("GML_"+_vm._s(_vm.MfMACCS1)+"_SP")]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'GML_' + _vm.MfMACCS1 + '_SP',
                _vm.infoList['GML_' + _vm.MfMACCS1 + '_SP_node_string'],
                'GML_' + _vm.MfMACCS1 + '_SP_BM',
                '水位设定值'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['GML_' + _vm.MfMACCS1 + '_SP'],
                'GML_' + _vm.MfMACCS1 + '_SP',
                _vm.infoList['GML_' + _vm.MfMACCS1 + '_SP_node_string'],
                'GML_' + _vm.MfMACCS1 + '_SP_BM'
              )}}},[_vm._v(" "+_vm._s(("GML_" + _vm.MfMACCS1 + "_SP") in _vm.infoList ? _vm.infoList["GML_" + _vm.MfMACCS1 + "_SP"] : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col2"}),_c('div',{staticClass:"bottonx jg1",style:({
            background:
              _vm.infoList['GMJ_XK' + _vm.MfMACCS2] &&
              !_vm.infoList['GMJ_XK' + _vm.MfMACCS2].RM
                ? '#2AFC30'
                : 'red',
          }),on:{"click":function($event){_vm.infoList['GMJ_XK' + _vm.MfMACCS2]
              ? _vm.toDetail(
                  1,
                  'GMJ_XK' + _vm.MfMACCS2,
                  _vm.infoList['GMJ_XK' + _vm.MfMACCS2 + '_node_string'],
                  '',
                  _vm.MfMACCS1 + '给煤机先控调节'
                )
              : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"fl row1-span3"},[_c('div',{staticClass:"row1-span3-col1 row1-span2-jg2"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("GMJ_MAN"+_vm._s(_vm.MfMACCS2))]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                _vm.infoList['GMJ_MAN' + _vm.MfMACCS2 + '_node_string'],
                'GMJ_MAN' + _vm.MfMACCS2 + '_AV_BM',
                '屏过出口设定值输出',
                ("GMJ_MAN" + _vm.MfMACCS2)
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['GMJ_MAN' + _vm.MfMACCS2].AV,
                'AV',
                _vm.infoList['GMJ_MAN' + _vm.MfMACCS2 + '_node_string'],
                'GMJ_MAN' + _vm.MfMACCS2 + '_AV_BM'
              )}}},[_vm._v(" "+_vm._s(("GMJ_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["GMJ_MAN" + _vm.MfMACCS2].AV : 0)+" ")])]),_c('div',{staticClass:"row1-span3-col1"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("GMJ_MV"+_vm._s(_vm.MfMACCS1)+"_HL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['GMJ_MAN' + _vm.MfMACCS2].OutT,
                'GMJ_MV' + _vm.MfMACCS1 + '_HL',
                'OutT',
                _vm.infoList['GMJ_MAN' + _vm.MfMACCS2 + '_node_string']
              )}}},[_vm._v(" "+_vm._s(("GMJ_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["GMJ_MAN" + _vm.MfMACCS2].OutT : 0)+" ")])]),_c('div',{staticClass:"row1-span3-col1"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("GMJ_MV"+_vm._s(_vm.MfMACCS1)+"_LL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['GMJ_MAN' + _vm.MfMACCS2].OutB,
                'GMJ_MV' + _vm.MfMACCS1 + '_LL',
                'OutB',
                _vm.infoList['GMJ_MAN' + _vm.MfMACCS2 + '_node_string']
              )}}},[_vm._v(" "+_vm._s(("GMJ_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["GMJ_MAN" + _vm.MfMACCS2].OutB : 0)+" ")])])])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span2-col1 row2-jg fl"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("ZQF_SP_TC")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.ZQF_SP_TC,
              ' ZQF_SP_TC',
              'ZQF_SP_TC',
              _vm.infoList.ZQF_SP_TC_node_string
            )}}},[_vm._v(" "+_vm._s("ZQF_SP_TC" in _vm.infoList ? _vm.infoList.ZQF_SP_TC : 0)+" ")])]),_c('div',{staticClass:"bottonx row2-jg2 fl",style:({
          background:
            ("GMJ_MAN" + _vm.MfMACCS2) ||
            ("MFL_MAN" + _vm.MfMACCS2) ||
            ("CKT_MAN" + _vm.MfMACCS2) in _vm.infoList
              ? _vm.infoList['GMJ_MAN' + _vm.MfMACCS2].RM == 1 ||
                _vm.infoList['MFL_MAN' + _vm.MfMACCS2].RM == 1 ||
                _vm.infoList['CKT_MAN' + _vm.MfMACCS2].RM == 1
                ? '#2AFC30'
                : 'red'
              : 'red',
        }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MAN' + _vm.MfMACCS1 + 'M', 3, _vm.MfMACCS1, _vm.MfMACCS2)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"row3"},[_c('div',{staticClass:"fl row1-span1"},[_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("MFL_SPH")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.MFL_SP_HL,
                ' MFL_SPH',
                'MFL_SP_HL',
                _vm.infoList.MFL_SP_HL_node_string
              )}}},[_vm._v(" "+_vm._s("MFL_SP_HL" in _vm.infoList ? _vm.infoList.MFL_SP_HL : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("MFL_SPL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.MFL_SP_LL,
                ' MFL_SPL',
                'MFL_SP_LL',
                _vm.infoList.MFL_SP_LL_node_string
              )}}},[_vm._v(" "+_vm._s("MFL_SP_LL" in _vm.infoList ? _vm.infoList.MFL_SP_LL : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1 row1-span2-jg1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("MFL_"+_vm._s(_vm.MfMACCS1)+"_TC")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['MFL_' + _vm.MfMACCS1 + '_TC'],
                'MFL_' + _vm.MfMACCS1 + '_TC',
                'MFL_' + _vm.MfMACCS1 + '_TC',
                _vm.infoList['MFL_' + _vm.MfMACCS1 + '_TC_node_string']
              )}}},[_vm._v(" "+_vm._s(("MFL_" + _vm.MfMACCS1 + "_TC") in _vm.infoList ? _vm.infoList["MFL_" + _vm.MfMACCS1 + "_TC"] : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("MFL_"+_vm._s(_vm.MfMACCS1)+"_PVL")]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'MFL_' + _vm.MfMACCS1 + '_PVL',
                _vm.infoList['MFL_' + _vm.MfMACCS1 + '_PVL_node_string'],
                'MFL_' + _vm.MfMACCS1 + '_PVL_BM',
                '屏过A温度过程滤波值'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['MFL_' + _vm.MfMACCS1 + '_PVL'],
                'MFL_' + _vm.MfMACCS1 + '_PVL',
                _vm.infoList['MFL_' + _vm.MfMACCS1 + '_PVL_node_string'],
                'MFL_' + _vm.MfMACCS1 + '_PVL_BM'
              )}}},[_vm._v(" "+_vm._s(("MFL_" + _vm.MfMACCS1 + "_PVL") in _vm.infoList ? _vm.infoList["MFL_" + _vm.MfMACCS1 + "_PVL"] : 0)+" ")])])]),_c('div',{staticClass:"fl row1-span2 row3-span2-jg"},[_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("MFL_"+_vm._s(_vm.MfMACCS1)+"_SP")]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'MFL_' + _vm.MfMACCS1 + '_SP',
                _vm.infoList['MFL_' + _vm.MfMACCS1 + '_SP_node_string'],
                'MFL_' + _vm.MfMACCS1 + '_SP_BM',
                '水位设定值'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['MFL_' + _vm.MfMACCS1 + '_SP'],
                'MFL_' + _vm.MfMACCS1 + '_SP',
                _vm.infoList['MFL_' + _vm.MfMACCS1 + '_SP_node_string'],
                'MFL_' + _vm.MfMACCS1 + '_SP_BM'
              )}}},[_vm._v(" "+_vm._s(("MFL_" + _vm.MfMACCS1 + "_SP") in _vm.infoList ? _vm.infoList["MFL_" + _vm.MfMACCS1 + "_SP"] : 0)+" ")])]),_c('div',{staticClass:"row3-span2-col2"},[_c('div',{staticClass:"bottonx fl",staticStyle:{"margin-top":"3vh","margin-left":"6.5vw"},style:({
              background:
                _vm.infoList['MFL_XK' + _vm.MfMACCS2] &&
                !_vm.infoList['MFL_XK' + _vm.MfMACCS2].RM
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList['MFL_XK' + _vm.MfMACCS2]
                ? _vm.toDetail(
                    1,
                    'MFL_XK' + _vm.MfMACCS2,
                    _vm.infoList['MFL_XK' + _vm.MfMACCS2 + '_node_string'],
                    '',
                    _vm.MfMACCS1 + '磨风量先控调节'
                  )
                : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"bottonx",staticStyle:{"margin-left":"23.5vw"},style:({
            background: !_vm.infoList['MFL_' + _vm.MfMACCS1 + '_QK1'].TS
              ? '#2AFC30'
              : 'red',
          }),on:{"click":function($event){_vm.infoList['MFL_' + _vm.MfMACCS1 + '_QK1']
              ? _vm.toDetail(
                  2,
                  'MFL_' + _vm.MfMACCS1 + '_QK1',
                  _vm.infoList['MFL_' + _vm.MfMACCS1 + '_QK1_node_string'],
                  '',
                  '磨风量前馈'
                )
              : ''}}},[_vm._v(" Q ")])]),_c('div',{staticClass:"fl row1-span3"},[_c('div',{staticClass:"row1-span3-col1 row1-span2-jg2"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("MFL_MAN"+_vm._s(_vm.MfMACCS2))]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                _vm.infoList['MFL_MAN' + _vm.MfMACCS2 + '_node_string'],
                'MFL_MAN' + _vm.MfMACCS2 + '_AV_BM',
                '屏过出口设定值输出',
                ("MFL_MAN" + _vm.MfMACCS2)
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['MFL_MAN' + _vm.MfMACCS2].AV,
                'AV',
                _vm.infoList['MFL_MAN' + _vm.MfMACCS2 + '_node_string'],
                'MFL_MAN' + _vm.MfMACCS2 + '_AV_BM'
              )}}},[_vm._v(" "+_vm._s(("MFL_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["MFL_MAN" + _vm.MfMACCS2].AV : 0)+" ")])]),_c('div',{staticClass:"row1-span3-col1"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("MFL_MV"+_vm._s(_vm.MfMACCS1)+"_HL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['MFL_MAN' + _vm.MfMACCS2].OutT,
                'MFL_MV' + _vm.MfMACCS1 + '_HL',
                'OutT',
                _vm.infoList['MFL_MAN' + _vm.MfMACCS2 + '_node_string']
              )}}},[_vm._v(" "+_vm._s(("MFL_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["MFL_MAN" + _vm.MfMACCS2].OutT : 0)+" ")])]),_c('div',{staticClass:"row1-span3-col1"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("MFL_MV"+_vm._s(_vm.MfMACCS1)+"_LL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['MFL_MAN' + _vm.MfMACCS2].OutB,
                'MFL_MV' + _vm.MfMACCS1 + '_LL',
                'OutB',
                _vm.infoList['MFL_MAN' + _vm.MfMACCS2 + '_node_string']
              )}}},[_vm._v(" "+_vm._s(("MFL_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["MFL_MAN" + _vm.MfMACCS2].OutB : 0)+" ")])])])]),_c('div',{staticClass:"row3 row3-jg3"},[_c('div',{staticClass:"fl row1-span1"},[_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("CKT_SPH")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.CKT_SP_HL,
                'CKT_SPH',
                'CKT_SP_HL',
                _vm.infoList.CKT_SP_HL_node_string
              )}}},[_vm._v(" "+_vm._s("CKT_SP_HL" in _vm.infoList ? _vm.infoList.CKT_SP_HL : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("CKT_SPL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.CKT_SP_LL,
                'CKT_SPL',
                'CKT_SP_LL',
                _vm.infoList.CKT_SP_LL_node_string
              )}}},[_vm._v(" "+_vm._s("CKT_SP_LL" in _vm.infoList ? _vm.infoList.CKT_SP_LL : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1 row1-span2-jg1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("CKT_"+_vm._s(_vm.MfMACCS1)+"_TC")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['CKT_' + _vm.MfMACCS1 + '_TC'],
                'CKT_' + _vm.MfMACCS1 + '_TC',
                'CKT_' + _vm.MfMACCS1 + '_TC',
                _vm.infoList['CKT_' + _vm.MfMACCS1 + '_TC_node_string']
              )}}},[_vm._v(" "+_vm._s(("CKT_" + _vm.MfMACCS1 + "_TC") in _vm.infoList ? _vm.infoList["CKT_" + _vm.MfMACCS1 + "_TC"] : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("CKT_"+_vm._s(_vm.MfMACCS1)+"_PVL")]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'CKT_' + _vm.MfMACCS1 + '_PVL',
                _vm.infoList['CKT_' + _vm.MfMACCS1 + '_PVL_node_string'],
                'CKT_' + _vm.MfMACCS1 + '_PVL_BM',
                '屏过A温度过程滤波值'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['CKT_' + _vm.MfMACCS1 + '_PVL'],
                'CKT_' + _vm.MfMACCS1 + '_PVL',
                _vm.infoList['CKT_' + _vm.MfMACCS1 + '_PVL_node_string'],
                'CKT_' + _vm.MfMACCS1 + '_PVL_BM'
              )}}},[_vm._v(" "+_vm._s(("CKT_" + _vm.MfMACCS1 + "_PVL") in _vm.infoList ? _vm.infoList["CKT_" + _vm.MfMACCS1 + "_PVL"] : 0)+" ")])])]),_c('div',{staticClass:"fl row1-span2 row3-span2-jg"},[_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("CKT_"+_vm._s(_vm.MfMACCS1)+"_SP")]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'CKT_' + _vm.MfMACCS1 + '_SP',
                _vm.infoList['CKT_' + _vm.MfMACCS1 + '_SP_node_string'],
                'CKT_' + _vm.MfMACCS1 + '_SP_BM',
                '水位设定值'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['CKT_' + _vm.MfMACCS1 + '_SP'],
                'CKT_' + _vm.MfMACCS1 + '_SP',
                _vm.infoList['CKT_' + _vm.MfMACCS1 + '_SP_node_string'],
                'CKT_' + _vm.MfMACCS1 + '_SP_BM'
              )}}},[_vm._v(" "+_vm._s(("CKT_" + _vm.MfMACCS1 + "_SP") in _vm.infoList ? _vm.infoList["CKT_" + _vm.MfMACCS1 + "_SP"] : 0)+" ")])]),_c('div',{staticClass:"row3-span2-col2"}),_c('div',{staticClass:"bottonx row3-jg1",style:({
            background:
              _vm.infoList['CKT_XK' + _vm.MfMACCS2] &&
              !_vm.infoList['CKT_XK' + _vm.MfMACCS2].RM
                ? '#2AFC30'
                : 'red',
          }),on:{"click":function($event){_vm.infoList['CKT_XK' + _vm.MfMACCS2]
              ? _vm.toDetail(
                  1,
                  'CKT_XK' + _vm.MfMACCS2,
                  _vm.infoList['CKT_XK' + _vm.MfMACCS2 + '_node_string'],
                  '',
                  _vm.MfMACCS1 + '磨出口温度先控调节'
                )
              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx row3-jg2",style:({
            background: !_vm.infoList['CKT_' + _vm.MfMACCS1 + '_QK1'].TS
              ? '#2AFC30'
              : 'red',
          }),on:{"click":function($event){_vm.infoList['CKT_' + _vm.MfMACCS1 + '_QK1']
              ? _vm.toDetail(
                  2,
                  'CKT_' + _vm.MfMACCS1 + '_QK1',
                  _vm.infoList['CKT_' + _vm.MfMACCS1 + '_QK1_node_string'],
                  '',
                  '磨温度前馈'
                )
              : ''}}},[_vm._v(" Q ")])]),_c('div',{staticClass:"fl row1-span3"},[_c('div',{staticClass:"row1-span3-col1 row1-span2-jg2"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("CKT_MAN"+_vm._s(_vm.MfMACCS2))]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                _vm.infoList['CKT_MAN' + _vm.MfMACCS2 + '_node_string'],
                'CKT_MAN' + _vm.MfMACCS2 + '_AV_BM',
                '屏过出口设定值输出',
                ("CKT_MAN" + _vm.MfMACCS2)
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['CKT_MAN' + _vm.MfMACCS2].AV,
                'AV',
                _vm.infoList['CKT_MAN' + _vm.MfMACCS2 + '_node_string'],
                'CKT_MAN' + _vm.MfMACCS2 + '_AV_BM'
              )}}},[_vm._v(" "+_vm._s(("CKT_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["CKT_MAN" + _vm.MfMACCS2].AV : 0)+" ")])]),_c('div',{staticClass:"row1-span3-col1"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("CKT_MV"+_vm._s(_vm.MfMACCS1)+"_HL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['CKT_MAN' + _vm.MfMACCS2].OutT,
                'CKT_MV' + _vm.MfMACCS1 + '_HL',
                'OutT',
                _vm.infoList['CKT_MAN' + _vm.MfMACCS2 + '_node_string']
              )}}},[_vm._v(" "+_vm._s(("CKT_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["CKT_MAN" + _vm.MfMACCS2].OutT : 0)+" ")])]),_c('div',{staticClass:"row1-span3-col1"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("CKT_MV"+_vm._s(_vm.MfMACCS1)+"_LL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['CKT_MAN' + _vm.MfMACCS2].OutB,
                'CKT_MV' + _vm.MfMACCS1 + '_LL',
                'OutB',
                _vm.infoList['CKT_MAN' + _vm.MfMACCS2 + '_node_string']
              )}}},[_vm._v(" "+_vm._s(("CKT_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["CKT_MAN" + _vm.MfMACCS2].OutB : 0)+" ")])])])]),_c('div',{staticClass:"row3 row3-jg3"},[_c('div',{staticClass:"fl row1-span1"},[_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("MYP_SPH")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.MYPSP_HL,
                ' MYP_SPH',
                'MYPSP_HL',
                _vm.infoList.MYPSP_HL_node_string
              )}}},[_vm._v(" "+_vm._s("MYPSP_HL" in _vm.infoList ? _vm.infoList.MYPSP_HL : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("MYP_SPL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.MYPSP_LL,
                ' MYP_SPL',
                'MYPSP_LL',
                _vm.infoList.MYPSP_LL_node_string
              )}}},[_vm._v(" "+_vm._s("MYPSP_LL" in _vm.infoList ? _vm.infoList.MYPSP_LL : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1 row1-span2-jg1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("MYP_"+_vm._s(_vm.MfMACCS1)+"_TC")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['MYP_' + _vm.MfMACCS1 + '_TC'],
                'MYP_' + _vm.MfMACCS1 + '_TC',
                'MYP_' + _vm.MfMACCS1 + '_TC',
                _vm.infoList['MYP_' + _vm.MfMACCS1 + '_TC_node_string']
              )}}},[_vm._v(" "+_vm._s(("MYP_" + _vm.MfMACCS1 + "_TC") in _vm.infoList ? _vm.infoList["MYP_" + _vm.MfMACCS1 + "_TC"] : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("MYP_"+_vm._s(_vm.MfMACCS1)+"_PVL")]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'MYP_' + _vm.MfMACCS1 + '_PVL',
                _vm.infoList['MYP_' + _vm.MfMACCS1 + '_PVL_node_string'],
                'MYP_' + _vm.MfMACCS1 + '_PVL_BM',
                '屏过A温度过程滤波值'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['MYP_' + _vm.MfMACCS1 + '_PVL'],
                'MYP_' + _vm.MfMACCS1 + '_PVL',
                _vm.infoList['MYP_' + _vm.MfMACCS1 + '_PVL_node_string'],
                'MYP_' + _vm.MfMACCS1 + '_PVL_BM'
              )}}},[_vm._v(" "+_vm._s(("MYP_" + _vm.MfMACCS1 + "_PVL") in _vm.infoList ? _vm.infoList["MYP_" + _vm.MfMACCS1 + "_PVL"] : 0)+" ")])])]),_c('div',{staticClass:"fl row1-span2 row3-span2-jg"},[_c('div',{staticClass:"row1-span2-col1"},[_c('div',{staticClass:"widthwd1 color1 fl"},[_vm._v("MYP_"+_vm._s(_vm.MfMACCS1)+"_SP")]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'MYP_' + _vm.MfMACCS1 + '_SP',
                _vm.infoList['MYP_' + _vm.MfMACCS1 + '_SP_node_string'],
                'MYP_' + _vm.MfMACCS1 + '_SP_BM',
                '水位设定值'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['MYP_' + _vm.MfMACCS1 + '_SP'],
                'MYP_' + _vm.MfMACCS1 + '_SP',
                _vm.infoList['MYP_' + _vm.MfMACCS1 + '_SP_node_string'],
                'MYP_' + _vm.MfMACCS1 + '_SP_BM'
              )}}},[_vm._v(" "+_vm._s(("MYP_" + _vm.MfMACCS1 + "_SP") in _vm.infoList ? _vm.infoList["MYP_" + _vm.MfMACCS1 + "_SP"] : 0)+" ")])]),_c('div',{staticClass:"row1-span2-col2",staticStyle:{"margin-top":"1vh"}},[_c('div',{staticClass:"bottonx row3-jg1 fl",staticStyle:{"margin-top":"2vh"},style:({
              background:
                _vm.infoList['MYP_XK' + _vm.MfMACCS2] &&
                !_vm.infoList['MYP_XK' + _vm.MfMACCS2].RM
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList['MYP_XK' + _vm.MfMACCS2]
                ? _vm.toDetail(
                    1,
                    'MYP_XK' + _vm.MfMACCS2,
                    _vm.infoList['MYP_XK' + _vm.MfMACCS2 + '_node_string'],
                    '',
                    _vm.MfMACCS1 + '磨油压先控调节'
                  )
                : ''}}},[_vm._v(" X ")])])]),_c('div',{staticClass:"fl row1-span3"},[_c('div',{staticClass:"row1-span3-col1 row1-span2-jg2"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("MYP_MAN"+_vm._s(_vm.MfMACCS2))]),_c('div',{staticClass:"widthwd2 color1 fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                _vm.infoList['MYP_MAN' + _vm.MfMACCS2 + '_node_string'],
                'MYP_MAN' + _vm.MfMACCS2 + '_AV_BM',
                '屏过出口设定值输出',
                ("MYP_MAN" + _vm.MfMACCS2)
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['MYP_MAN' + _vm.MfMACCS2].AV,
                'AV',
                _vm.infoList['MYP_MAN' + _vm.MfMACCS2 + '_node_string'],
                'MYP_MAN' + _vm.MfMACCS2 + '_AV_BM'
              )}}},[_vm._v(" "+_vm._s(("MYP_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["MYP_MAN" + _vm.MfMACCS2].AV : 0)+" ")])]),_c('div',{staticClass:"row1-span3-col1"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("MYP_MV"+_vm._s(_vm.MfMACCS1)+"_HL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['MYP_MAN' + _vm.MfMACCS2].OutT,
                'MYP_MV' + _vm.MfMACCS1 + '_HL',
                'OutT',
                _vm.infoList['MYP_MAN' + _vm.MfMACCS2 + '_node_string']
              )}}},[_vm._v(" "+_vm._s(("MYP_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["MYP_MAN" + _vm.MfMACCS2].OutT : 0)+" ")])]),_c('div',{staticClass:"row1-span3-col1"},[_c('div',{staticClass:"widthwd3 color1 fl"},[_vm._v("MYP_MV"+_vm._s(_vm.MfMACCS1)+"_LL")]),_c('div',{staticClass:"widthwd2 color2 fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['MYP_MAN' + _vm.MfMACCS2].OutB,
                'MYP_MV' + _vm.MfMACCS1 + '_LL',
                'OutB',
                _vm.infoList['MYP_MAN' + _vm.MfMACCS2 + '_node_string']
              )}}},[_vm._v(" "+_vm._s(("MYP_MAN" + _vm.MfMACCS2) in _vm.infoList ? _vm.infoList["MYP_MAN" + _vm.MfMACCS2].OutB : 0)+" ")])])])])]),(_vm.mflmanyManual)?_c('mflmanyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"mark":_vm.Manualmark,"manvar":_vm.Manualvar,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }