<template>
  <div class="mflmac drag" id="mflmac" v-draw>
    <!-- 标题部分 -->
    <div>
      <div class="title-hang" fl>
        {{ projectData.project }}_M{{ MfMACCS1 }}_C
      </div>
      <div class="param11-header-icon" fr @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
    <div class="titlelable">{{ MfMACCS1 }}磨优化控制模型</div>
    <div class="main">
      <div class="row1">
        <div class="fl row1-span1">
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">GML_SPH</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList.GML_SP_HL,
                  'GML_SPH',
                  'GML_SP_HL',
                  infoList.GML_SP_HL_node_string
                )
              "
            >
              {{ "GML_SP_HL" in infoList ? infoList.GML_SP_HL : 0 }}
            </div>
          </div>
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">GML_SPL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList.GML_SP_LL,
                  ' GML_SPL',
                  'GML_SP_LL',
                  infoList.GML_SP_LL_node_string
                )
              "
            >
              {{ "GML_SP_LL" in infoList ? infoList.GML_SP_LL : 0 }}
            </div>
          </div>
          <div class="row1-span2-col1 row1-span2-jg1">
            <div class="widthwd1 color1 fl">GML_{{ MfMACCS1 }}_TC</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['GML_' + MfMACCS1 + '_TC'],
                  'GML_' + MfMACCS1 + '_TC',
                  'GML_' + MfMACCS1 + '_TC',
                  infoList['GML_' + MfMACCS1 + '_TC_node_string']
                )
              "
            >
              {{
                `GML_${MfMACCS1}_TC` in infoList
                  ? infoList["GML_" + MfMACCS1 + "_TC"]
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">GML_{{ MfMACCS1 }}_PVL</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'GML_' + MfMACCS1 + '_PVL',
                  infoList['GML_' + MfMACCS1 + '_PVL_node_string'],
                  'GML_' + MfMACCS1 + '_PVL_BM',
                  'A磨煤机油压滤波值'
                )
              "
              @dblclick="
                Cclick(
                  infoList['GML_' + MfMACCS1 + '_PVL'],
                  'GML_' + MfMACCS1 + '_PVL',
                  infoList['GML_' + MfMACCS1 + '_PVL_node_string'],
                  'GML_' + MfMACCS1 + '_PVL_BM'
                )
              "
            >
              {{
                `GML_${MfMACCS1}_PVL` in infoList
                  ? infoList["GML_" + MfMACCS1 + "_PVL"]
                  : 0
              }}
            </div>
          </div>
        </div>
        <div class="fl row1-span2 row1-span2-jg">
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">GML_{{ MfMACCS1 }}_SP</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'GML_' + MfMACCS1 + '_SP',
                  infoList['GML_' + MfMACCS1 + '_SP_node_string'],
                  'GML_' + MfMACCS1 + '_SP_BM',
                  '水位设定值'
                )
              "
              @dblclick="
                Cclick(
                  infoList['GML_' + MfMACCS1 + '_SP'],
                  'GML_' + MfMACCS1 + '_SP',
                  infoList['GML_' + MfMACCS1 + '_SP_node_string'],
                  'GML_' + MfMACCS1 + '_SP_BM'
                )
              "
            >
              {{
                `GML_${MfMACCS1}_SP` in infoList
                  ? infoList["GML_" + MfMACCS1 + "_SP"]
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span2-col2"></div>
          <div
            class="bottonx jg1"
            :style="{
              background:
                infoList['GMJ_XK' + MfMACCS2] &&
                !infoList['GMJ_XK' + MfMACCS2].RM
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList['GMJ_XK' + MfMACCS2]
                ? toDetail(
                    1,
                    'GMJ_XK' + MfMACCS2,
                    infoList['GMJ_XK' + MfMACCS2 + '_node_string'],
                    '',
                    MfMACCS1 + '给煤机先控调节'
                  )
                : ''
            "
          >
            X
          </div>
        </div>
        <div class="fl row1-span3">
          <div class="row1-span3-col1 row1-span2-jg2">
            <div class="widthwd3 color1 fl">GMJ_MAN{{ MfMACCS2 }}</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'AV',
                  infoList['GMJ_MAN' + MfMACCS2 + '_node_string'],
                  'GMJ_MAN' + MfMACCS2 + '_AV_BM',
                  '屏过出口设定值输出',
                  `GMJ_MAN${MfMACCS2}`
                )
              "
              @dblclick="
                Cclick(
                  infoList['GMJ_MAN' + MfMACCS2].AV,
                  'AV',
                  infoList['GMJ_MAN' + MfMACCS2 + '_node_string'],
                  'GMJ_MAN' + MfMACCS2 + '_AV_BM'
                )
              "
            >
              {{
                `GMJ_MAN${MfMACCS2}` in infoList
                  ? infoList["GMJ_MAN" + MfMACCS2].AV
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span3-col1">
            <div class="widthwd3 color1 fl">GMJ_MV{{ MfMACCS1 }}_HL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['GMJ_MAN' + MfMACCS2].OutT,
                  'GMJ_MV' + MfMACCS1 + '_HL',
                  'OutT',
                  infoList['GMJ_MAN' + MfMACCS2 + '_node_string']
                )
              "
            >
              {{
                `GMJ_MAN${MfMACCS2}` in infoList
                  ? infoList["GMJ_MAN" + MfMACCS2].OutT
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span3-col1">
            <div class="widthwd3 color1 fl">GMJ_MV{{ MfMACCS1 }}_LL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['GMJ_MAN' + MfMACCS2].OutB,
                  'GMJ_MV' + MfMACCS1 + '_LL',
                  'OutB',
                  infoList['GMJ_MAN' + MfMACCS2 + '_node_string']
                )
              "
            >
              {{
                `GMJ_MAN${MfMACCS2}` in infoList
                  ? infoList["GMJ_MAN" + MfMACCS2].OutB
                  : 0
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="row2">
        <div class="row1-span2-col1 row2-jg fl">
          <div class="widthwd1 color1 fl">ZQF_SP_TC</div>
          <div
            class="widthwd2 color2 fl"
            @click="
              toIpt(
                infoList.ZQF_SP_TC,
                ' ZQF_SP_TC',
                'ZQF_SP_TC',
                infoList.ZQF_SP_TC_node_string
              )
            "
          >
            {{ `ZQF_SP_TC` in infoList ? infoList.ZQF_SP_TC : 0 }}
          </div>
        </div>
        <!-- :style="{
              background:
                infoList['GMJ_MAN' + MfMACCS2] &&
                infoList['GMJ_MAN' + MfMACCS2].RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList['GMJ_MAN' + MfMACCS2]
                ? toCompon(
                    0,
                    'RM',
                    infoList['GMJ_MAN' + MfMACCS2+ '_node_string'],
                    'GMJ_MAN'+ MfMACCS2 +'_RM_BM',
                    '煤粉炉_MAN'
                  )
                : ''
            " -->
        <div
          class="bottonx row2-jg2 fl"
          :style="{
            background:
              `GMJ_MAN${MfMACCS2}` ||
              `MFL_MAN${MfMACCS2}` ||
              `CKT_MAN${MfMACCS2}` in infoList
                ? infoList['GMJ_MAN' + MfMACCS2].RM == 1 ||
                  infoList['MFL_MAN' + MfMACCS2].RM == 1 ||
                  infoList['CKT_MAN' + MfMACCS2].RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red',
          }"
          @click="
            toCompon(0, 'MFL_MAN' + MfMACCS1 + 'M', 3, MfMACCS1, MfMACCS2)
          "
        >
          A
        </div>
      </div>
      <div class="row3">
        <div class="fl row1-span1">
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">MFL_SPH</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList.MFL_SP_HL,
                  ' MFL_SPH',
                  'MFL_SP_HL',
                  infoList.MFL_SP_HL_node_string
                )
              "
            >
              {{ `MFL_SP_HL` in infoList ? infoList.MFL_SP_HL : 0 }}
            </div>
          </div>
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">MFL_SPL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList.MFL_SP_LL,
                  ' MFL_SPL',
                  'MFL_SP_LL',
                  infoList.MFL_SP_LL_node_string
                )
              "
            >
              {{ `MFL_SP_LL` in infoList ? infoList.MFL_SP_LL : 0 }}
            </div>
          </div>
          <div class="row1-span2-col1 row1-span2-jg1">
            <div class="widthwd1 color1 fl">MFL_{{ MfMACCS1 }}_TC</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['MFL_' + MfMACCS1 + '_TC'],
                  'MFL_' + MfMACCS1 + '_TC',
                  'MFL_' + MfMACCS1 + '_TC',
                  infoList['MFL_' + MfMACCS1 + '_TC_node_string']
                )
              "
            >
              {{
                `MFL_${MfMACCS1}_TC` in infoList
                  ? infoList["MFL_" + MfMACCS1 + "_TC"]
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">MFL_{{ MfMACCS1 }}_PVL</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'MFL_' + MfMACCS1 + '_PVL',
                  infoList['MFL_' + MfMACCS1 + '_PVL_node_string'],
                  'MFL_' + MfMACCS1 + '_PVL_BM',
                  '屏过A温度过程滤波值'
                )
              "
              @dblclick="
                Cclick(
                  infoList['MFL_' + MfMACCS1 + '_PVL'],
                  'MFL_' + MfMACCS1 + '_PVL',
                  infoList['MFL_' + MfMACCS1 + '_PVL_node_string'],
                  'MFL_' + MfMACCS1 + '_PVL_BM'
                )
              "
            >
              {{
                `MFL_${MfMACCS1}_PVL` in infoList
                  ? infoList["MFL_" + MfMACCS1 + "_PVL"]
                  : 0
              }}
            </div>
          </div>
        </div>
        <div class="fl row1-span2 row3-span2-jg">
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">MFL_{{ MfMACCS1 }}_SP</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'MFL_' + MfMACCS1 + '_SP',
                  infoList['MFL_' + MfMACCS1 + '_SP_node_string'],
                  'MFL_' + MfMACCS1 + '_SP_BM',
                  '水位设定值'
                )
              "
              @dblclick="
                Cclick(
                  infoList['MFL_' + MfMACCS1 + '_SP'],
                  'MFL_' + MfMACCS1 + '_SP',
                  infoList['MFL_' + MfMACCS1 + '_SP_node_string'],
                  'MFL_' + MfMACCS1 + '_SP_BM'
                )
              "
            >
              {{
                `MFL_${MfMACCS1}_SP` in infoList
                  ? infoList["MFL_" + MfMACCS1 + "_SP"]
                  : 0
              }}
            </div>
          </div>
          <div class="row3-span2-col2">
            <div
              class="bottonx fl"
              style="margin-top: 3vh; margin-left: 6.5vw"
              :style="{
                background:
                  infoList['MFL_XK' + MfMACCS2] &&
                  !infoList['MFL_XK' + MfMACCS2].RM
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList['MFL_XK' + MfMACCS2]
                  ? toDetail(
                      1,
                      'MFL_XK' + MfMACCS2,
                      infoList['MFL_XK' + MfMACCS2 + '_node_string'],
                      '',
                      MfMACCS1 + '磨风量先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
          </div>
          <div
            class="bottonx"
            style="margin-left: 23.5vw"
            :style="{
              background: !infoList['MFL_' + MfMACCS1 + '_QK1'].TS
                ? '#2AFC30'
                : 'red',
            }"
            @click="
              infoList['MFL_' + MfMACCS1 + '_QK1']
                ? toDetail(
                    2,
                    'MFL_' + MfMACCS1 + '_QK1',
                    infoList['MFL_' + MfMACCS1 + '_QK1_node_string'],
                    '',
                    '磨风量前馈'
                  )
                : ''
            "
          >
            Q
          </div>
        </div>
        <div class="fl row1-span3">
          <div class="row1-span3-col1 row1-span2-jg2">
            <div class="widthwd3 color1 fl">MFL_MAN{{ MfMACCS2 }}</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'AV',
                  infoList['MFL_MAN' + MfMACCS2 + '_node_string'],
                  'MFL_MAN' + MfMACCS2 + '_AV_BM',
                  '屏过出口设定值输出',
                  `MFL_MAN${MfMACCS2}`
                )
              "
              @dblclick="
                Cclick(
                  infoList['MFL_MAN' + MfMACCS2].AV,
                  'AV',
                  infoList['MFL_MAN' + MfMACCS2 + '_node_string'],
                  'MFL_MAN' + MfMACCS2 + '_AV_BM'
                )
              "
            >
              {{
                `MFL_MAN${MfMACCS2}` in infoList
                  ? infoList["MFL_MAN" + MfMACCS2].AV
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span3-col1">
            <div class="widthwd3 color1 fl">MFL_MV{{ MfMACCS1 }}_HL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['MFL_MAN' + MfMACCS2].OutT,
                  'MFL_MV' + MfMACCS1 + '_HL',
                  'OutT',
                  infoList['MFL_MAN' + MfMACCS2 + '_node_string']
                )
              "
            >
              {{
                `MFL_MAN${MfMACCS2}` in infoList
                  ? infoList["MFL_MAN" + MfMACCS2].OutT
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span3-col1">
            <div class="widthwd3 color1 fl">MFL_MV{{ MfMACCS1 }}_LL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['MFL_MAN' + MfMACCS2].OutB,
                  'MFL_MV' + MfMACCS1 + '_LL',
                  'OutB',
                  infoList['MFL_MAN' + MfMACCS2 + '_node_string']
                )
              "
            >
              {{
                `MFL_MAN${MfMACCS2}` in infoList
                  ? infoList["MFL_MAN" + MfMACCS2].OutB
                  : 0
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="row3 row3-jg3">
        <div class="fl row1-span1">
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">CKT_SPH</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList.CKT_SP_HL,
                  'CKT_SPH',
                  'CKT_SP_HL',
                  infoList.CKT_SP_HL_node_string
                )
              "
            >
              {{ `CKT_SP_HL` in infoList ? infoList.CKT_SP_HL : 0 }}
            </div>
          </div>
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">CKT_SPL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList.CKT_SP_LL,
                  'CKT_SPL',
                  'CKT_SP_LL',
                  infoList.CKT_SP_LL_node_string
                )
              "
            >
              {{ `CKT_SP_LL` in infoList ? infoList.CKT_SP_LL : 0 }}
            </div>
          </div>
          <div class="row1-span2-col1 row1-span2-jg1">
            <div class="widthwd1 color1 fl">CKT_{{ MfMACCS1 }}_TC</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['CKT_' + MfMACCS1 + '_TC'],
                  'CKT_' + MfMACCS1 + '_TC',
                  'CKT_' + MfMACCS1 + '_TC',
                  infoList['CKT_' + MfMACCS1 + '_TC_node_string']
                )
              "
            >
              {{
                `CKT_${MfMACCS1}_TC` in infoList
                  ? infoList["CKT_" + MfMACCS1 + "_TC"]
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">CKT_{{ MfMACCS1 }}_PVL</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'CKT_' + MfMACCS1 + '_PVL',
                  infoList['CKT_' + MfMACCS1 + '_PVL_node_string'],
                  'CKT_' + MfMACCS1 + '_PVL_BM',
                  '屏过A温度过程滤波值'
                )
              "
              @dblclick="
                Cclick(
                  infoList['CKT_' + MfMACCS1 + '_PVL'],
                  'CKT_' + MfMACCS1 + '_PVL',
                  infoList['CKT_' + MfMACCS1 + '_PVL_node_string'],
                  'CKT_' + MfMACCS1 + '_PVL_BM'
                )
              "
            >
              {{
                `CKT_${MfMACCS1}_PVL` in infoList
                  ? infoList["CKT_" + MfMACCS1 + "_PVL"]
                  : 0
              }}
            </div>
          </div>
        </div>
        <div class="fl row1-span2 row3-span2-jg">
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">CKT_{{ MfMACCS1 }}_SP</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'CKT_' + MfMACCS1 + '_SP',
                  infoList['CKT_' + MfMACCS1 + '_SP_node_string'],
                  'CKT_' + MfMACCS1 + '_SP_BM',
                  '水位设定值'
                )
              "
              @dblclick="
                Cclick(
                  infoList['CKT_' + MfMACCS1 + '_SP'],
                  'CKT_' + MfMACCS1 + '_SP',
                  infoList['CKT_' + MfMACCS1 + '_SP_node_string'],
                  'CKT_' + MfMACCS1 + '_SP_BM'
                )
              "
            >
              {{
                `CKT_${MfMACCS1}_SP` in infoList
                  ? infoList["CKT_" + MfMACCS1 + "_SP"]
                  : 0
              }}
            </div>
          </div>
          <div class="row3-span2-col2"></div>
          <div
            class="bottonx row3-jg1"
            :style="{
              background:
                infoList['CKT_XK' + MfMACCS2] &&
                !infoList['CKT_XK' + MfMACCS2].RM
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList['CKT_XK' + MfMACCS2]
                ? toDetail(
                    1,
                    'CKT_XK' + MfMACCS2,
                    infoList['CKT_XK' + MfMACCS2 + '_node_string'],
                    '',
                    MfMACCS1 + '磨出口温度先控调节'
                  )
                : ''
            "
          >
            X
          </div>
          <div
            class="bottonx row3-jg2"
            :style="{
              background: !infoList['CKT_' + MfMACCS1 + '_QK1'].TS
                ? '#2AFC30'
                : 'red',
            }"
            @click="
              infoList['CKT_' + MfMACCS1 + '_QK1']
                ? toDetail(
                    2,
                    'CKT_' + MfMACCS1 + '_QK1',
                    infoList['CKT_' + MfMACCS1 + '_QK1_node_string'],
                    '',
                    '磨温度前馈'
                  )
                : ''
            "
          >
            Q
          </div>
        </div>
        <div class="fl row1-span3">
          <div class="row1-span3-col1 row1-span2-jg2">
            <div class="widthwd3 color1 fl">CKT_MAN{{ MfMACCS2 }}</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'AV',
                  infoList['CKT_MAN' + MfMACCS2 + '_node_string'],
                  'CKT_MAN' + MfMACCS2 + '_AV_BM',
                  '屏过出口设定值输出',
                  `CKT_MAN${MfMACCS2}`
                )
              "
              @dblclick="
                Cclick(
                  infoList['CKT_MAN' + MfMACCS2].AV,
                  'AV',
                  infoList['CKT_MAN' + MfMACCS2 + '_node_string'],
                  'CKT_MAN' + MfMACCS2 + '_AV_BM'
                )
              "
            >
              {{
                `CKT_MAN${MfMACCS2}` in infoList
                  ? infoList["CKT_MAN" + MfMACCS2].AV
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span3-col1">
            <div class="widthwd3 color1 fl">CKT_MV{{ MfMACCS1 }}_HL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['CKT_MAN' + MfMACCS2].OutT,
                  'CKT_MV' + MfMACCS1 + '_HL',
                  'OutT',
                  infoList['CKT_MAN' + MfMACCS2 + '_node_string']
                )
              "
            >
              {{
                `CKT_MAN${MfMACCS2}` in infoList
                  ? infoList["CKT_MAN" + MfMACCS2].OutT
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span3-col1">
            <div class="widthwd3 color1 fl">CKT_MV{{ MfMACCS1 }}_LL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['CKT_MAN' + MfMACCS2].OutB,
                  'CKT_MV' + MfMACCS1 + '_LL',
                  'OutB',
                  infoList['CKT_MAN' + MfMACCS2 + '_node_string']
                )
              "
            >
              {{
                `CKT_MAN${MfMACCS2}` in infoList
                  ? infoList["CKT_MAN" + MfMACCS2].OutB
                  : 0
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="row3 row3-jg3">
        <div class="fl row1-span1">
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">MYP_SPH</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList.MYPSP_HL,
                  ' MYP_SPH',
                  'MYPSP_HL',
                  infoList.MYPSP_HL_node_string
                )
              "
            >
              {{ `MYPSP_HL` in infoList ? infoList.MYPSP_HL : 0 }}
            </div>
          </div>
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">MYP_SPL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList.MYPSP_LL,
                  ' MYP_SPL',
                  'MYPSP_LL',
                  infoList.MYPSP_LL_node_string
                )
              "
            >
              {{ `MYPSP_LL` in infoList ? infoList.MYPSP_LL : 0 }}
            </div>
          </div>
          <div class="row1-span2-col1 row1-span2-jg1">
            <div class="widthwd1 color1 fl">MYP_{{ MfMACCS1 }}_TC</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['MYP_' + MfMACCS1 + '_TC'],
                  'MYP_' + MfMACCS1 + '_TC',
                  'MYP_' + MfMACCS1 + '_TC',
                  infoList['MYP_' + MfMACCS1 + '_TC_node_string']
                )
              "
            >
              {{
                `MYP_${MfMACCS1}_TC` in infoList
                  ? infoList["MYP_" + MfMACCS1 + "_TC"]
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">MYP_{{ MfMACCS1 }}_PVL</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'MYP_' + MfMACCS1 + '_PVL',
                  infoList['MYP_' + MfMACCS1 + '_PVL_node_string'],
                  'MYP_' + MfMACCS1 + '_PVL_BM',
                  '屏过A温度过程滤波值'
                )
              "
              @dblclick="
                Cclick(
                  infoList['MYP_' + MfMACCS1 + '_PVL'],
                  'MYP_' + MfMACCS1 + '_PVL',
                  infoList['MYP_' + MfMACCS1 + '_PVL_node_string'],
                  'MYP_' + MfMACCS1 + '_PVL_BM'
                )
              "
            >
              {{
                `MYP_${MfMACCS1}_PVL` in infoList
                  ? infoList["MYP_" + MfMACCS1 + "_PVL"]
                  : 0
              }}
            </div>
          </div>
        </div>
        <div class="fl row1-span2 row3-span2-jg">
          <div class="row1-span2-col1">
            <div class="widthwd1 color1 fl">MYP_{{ MfMACCS1 }}_SP</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'MYP_' + MfMACCS1 + '_SP',
                  infoList['MYP_' + MfMACCS1 + '_SP_node_string'],
                  'MYP_' + MfMACCS1 + '_SP_BM',
                  '水位设定值'
                )
              "
              @dblclick="
                Cclick(
                  infoList['MYP_' + MfMACCS1 + '_SP'],
                  'MYP_' + MfMACCS1 + '_SP',
                  infoList['MYP_' + MfMACCS1 + '_SP_node_string'],
                  'MYP_' + MfMACCS1 + '_SP_BM'
                )
              "
            >
              {{
                `MYP_${MfMACCS1}_SP` in infoList
                  ? infoList["MYP_" + MfMACCS1 + "_SP"]
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span2-col2" style="margin-top: 1vh">
            <div
              class="bottonx row3-jg1 fl"
              style="margin-top: 2vh"
              :style="{
                background:
                  infoList['MYP_XK' + MfMACCS2] &&
                  !infoList['MYP_XK' + MfMACCS2].RM
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList['MYP_XK' + MfMACCS2]
                  ? toDetail(
                      1,
                      'MYP_XK' + MfMACCS2,
                      infoList['MYP_XK' + MfMACCS2 + '_node_string'],
                      '',
                      MfMACCS1 + '磨油压先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
          </div>
        </div>
        <div class="fl row1-span3">
          <div class="row1-span3-col1 row1-span2-jg2">
            <div class="widthwd3 color1 fl">MYP_MAN{{ MfMACCS2 }}</div>
            <div
              class="widthwd2 color1 fl"
              @click="
                toCompon(
                  2,
                  'AV',
                  infoList['MYP_MAN' + MfMACCS2 + '_node_string'],
                  'MYP_MAN' + MfMACCS2 + '_AV_BM',
                  '屏过出口设定值输出',
                  `MYP_MAN${MfMACCS2}`
                )
              "
              @dblclick="
                Cclick(
                  infoList['MYP_MAN' + MfMACCS2].AV,
                  'AV',
                  infoList['MYP_MAN' + MfMACCS2 + '_node_string'],
                  'MYP_MAN' + MfMACCS2 + '_AV_BM'
                )
              "
            >
              {{
                `MYP_MAN${MfMACCS2}` in infoList
                  ? infoList["MYP_MAN" + MfMACCS2].AV
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span3-col1">
            <div class="widthwd3 color1 fl">MYP_MV{{ MfMACCS1 }}_HL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['MYP_MAN' + MfMACCS2].OutT,
                  'MYP_MV' + MfMACCS1 + '_HL',
                  'OutT',
                  infoList['MYP_MAN' + MfMACCS2 + '_node_string']
                )
              "
            >
              {{
                `MYP_MAN${MfMACCS2}` in infoList
                  ? infoList["MYP_MAN" + MfMACCS2].OutT
                  : 0
              }}
            </div>
          </div>
          <div class="row1-span3-col1">
            <div class="widthwd3 color1 fl">MYP_MV{{ MfMACCS1 }}_LL</div>
            <div
              class="widthwd2 color2 fl"
              @click="
                toIpt(
                  infoList['MYP_MAN' + MfMACCS2].OutB,
                  'MYP_MV' + MfMACCS1 + '_LL',
                  'OutB',
                  infoList['MYP_MAN' + MfMACCS2 + '_node_string']
                )
              "
            >
              {{
                `MYP_MAN${MfMACCS2}` in infoList
                  ? infoList["MYP_MAN" + MfMACCS2].OutB
                  : 0
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

     <mflmanyManual
      v-if="mflmanyManual"
      :titname="Manualtitname"
      :numM="Manualnode"
      :mark="Manualmark"
      :manvar="Manualvar"
      @sendStatus="isClose"
      :infoList="infoList"
    ></mflmanyManual>

     <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="historyname"
      :node="node"
      :node1="node1"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
  </div>
</template>
<script>
import Historical from "@/components/Historicaljrl";
import inputVal from "@/components/inputVal"; //输入框组件
import mflmanyManual from "@/components/MflmanyManual1.vue"; //多手操器
export default {
  name: "MFL_MAC",
  components: {
    mflmanyManual,
    Historical,
    inputVal,
  },
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
    MfFMAZXCS1: [String, Number],
    MfMACCS1: [String, Number],
    MfMACCS2: [String, Number],
    MfMACCS3: [String, Number],
    MfMACCS4: [String, Number],
  },
  data: () => {
    return {
      chName: "",
      mflmanyManual: false,
      Manualmark: "",
      Manualvar: "",
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.8vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      isMshow: false,
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
      spotArr: [],
      authInfo: [],
      node1: "",
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
  },
  computed: {},
  mounted() {
    // console.log(this.MfMACCS2)
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }
        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "mfkfirstcontol";
            break;
          case 3:
            pathname = "mfRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    closeCompon() {
      this.$emit("sendStatus", "MFL_MAC", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
        case "mflmanyManual":
          return (this.mflmanyManual = val1);
      }
    },
    isClose1() {
      this.isHshow = false;
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      switch (key) {
        case 0:
          return (
            (this.mflmanyManual = true),
            (this.Manualtitname = name),
            (this.Manualnode = name2),
            (this.Manualmark = name3),
            (this.Manualvar = name4)
          );
        case 1:
          return (
            (this.isFshow = true),
            (this.Firstcontrolname = name),
            (this.Firstcontrolnode = name2),
            (this.Firstcontroltitname = titname)
          );
        case 2:
          return (
            (this.isHshow = true), (this.chName = name4), (this.node1 = titname)
          );
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.mflmac {
  width: 53vw;
  height: 78vh;
  //  background: #e9f7ff;
  background-image: url("~@/assets/images/jrl-param.png");
  background-size: 100% 100%;
  //  cursor:move;
  border-radius: 5px;

  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }

  .main-top {
    margin-left: 3.5vw;
    font-size: 0.9vw;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .title-hang {
    height: 5vh;
    width: 53vw;
    font-family: MicrosoftYaHei;
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    padding-top: 0.5vh;
    /* // margin-top:vh; */
  }

  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }

  .title-hang {
    width: 100%;
    height: 6vh;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }

  .lable1 {
    font-family: PingFang-SC-Regular;
    font-size: 1.2vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2.5vh;
    letter-spacing: 0vh;
    color: #d5fffe;
    margin-left: 2.5vw;
    margin-top: 1vh;
  }

  .titlelable {
    width: 53vw;
    height: 3vh;
    font-family: PingFang-SC-Regular;
    font-size: 3vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0.3vw;
    color: #d5fffe;
    text-align: center;
    margin-top: 3vh;
  }

  .param11-header-icon {
    width: 2vw;
    height: 2vh;
    right: 1vw;
    margin-top: -5vh;
    cursor: pointer;
    margin-left: 50vw;
  }

  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 0.9vw;
    // margin-right: 0.1vw;
    color: #fff;
    cursor: pointer;
  }

  .row3 {
    height: 6vw;
    margin-top: 2vh;
  }

  .jg1 {
    margin-left: 6.3vw;
    margin-top: -1.8vh;
  }

  .row3-jg1 {
    margin-left: 6.3vw;
    margin-top: -5.2vh;
  }

  .row3-jg2 {
    margin-left: 23.3vw;
    margin-top: 2.6vh;
  }

  .row3-jg3 {
    margin-top: 2.6vh;
  }

  .main {
    font-size: 0.9vw;
  }

  .row1 {
    margin-top: 2vh;
    height: 7vw;
    // border: 1px saddlebrown solid;
  }

  .color1 {
    color: #8aeaff;
  }

  .color2 {
    color: #00ffb4;
  }

  .row1-span1 {
    width: 11vw;
    height: 10vh;
    margin-left: 2vh;
  }

  .widthwd1 {
    width: 6vw;
    // text-align: left;
    padding-left: 1vw;
  }

  .widthwd3 {
    width: 7vw;
    // text-align: left;
    padding-left: 1vw;
    // text-align: left;
  }

  .widthwd2 {
    width: 3vw;
    cursor: pointer;
    // text-align: center;
  }

  .row1-span2-jg {
    margin-top: 3vh;
  }

  .row3-span2-jg {
    margin-top: 1vh;
  }

  .row1-span2-jg1 {
    margin-top: 1vh;
  }

  .row1-span2-jg2 {
    margin-top: 5vh;
  }

  .row1-span2-col1 {
    width: 10vw;
    height: 3vh;
    background-color: #001b2860;
    border-radius: 0vw;
    border: solid 0vw #236f8d;
    line-height: 3vh;
  }

  .row1-span3-col1 {
    width: 11vw;
    height: 3vh;
    background-color: #001b2860;
    border-radius: 0vw;
    border: solid 0vw #236f8d;
    line-height: 3vh;
  }

  .row1-span2-col2 {
    width: 29vw;
    height: 4vh;
    background-image: url("~@/assets/images/MFL_A01.png");
    background-size: 100% 100%;
    z-index: 1;
  }

  .row3-span2-col2 {
    width: 29vw;
    height: 9vh;
    background-image: url("~@/assets/images/MFL_A02.png");
    background-size: 100% 100%;
    z-index: 1;
  }

  .row2 {
    width: 100%;
    height: 3vh;
  }

  .row2-jg {
    margin-left: 12vw;
  }

  .row2-jg2 {
    margin-left: 19vw;
  }
}
</style>
